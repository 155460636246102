<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card-title>{{ $t("common.newPassword") }}</v-card-title>

    <v-card-text>
      <v-text-field
        id="password"
        :label="$t('common.password')"
        name="password"
        prepend-icon="lock"
        type="password"
        v-model="password"
        :rules="passwordRules"
        v-on:change="updateValidation"
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <v-text-field
        id="confirmPassword"
        :label="$t('common.confirmPassword')"
        prepend-icon="lock"
        type="password"
        v-model="confirmPassword"
        :rules="passwordRules && confirmRule"
        v-on:change="updateValidation"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary" type="submit">{{ $t("common.send") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NewPassword",

  data() {
    return {
      valid: false,
      guid: "",
      password: "",
      confirmPassword: "",
      submitted: false,

      passwordRules: [
        v => !!v || this.$t("validation.requiredSingle"),
        v => (v && v.length <= 255) || this.$t("validation.toLong")
      ],
      confirmRule: [
        () =>
          this.password === this.confirmPassword ||
          this.$t("validation.passwordMissmatch")
      ]
    };
  },
  computed: {},

  created() {
    if (!this.$route.params.id) {
      this.$router.push("/login");
    }

    this.guid = this.$route.params.id;
  },

  methods: {
    ...mapActions("users", ["forgotPasswordUpdate"]),

    async handleSubmit(e) {
      e.preventDefault();
      this.updateValidation();

      this.submitted = true;
      const { password, confirmPassword } = this;
      if (password === confirmPassword && this.guid) {
        this.valid = true;
        await this.forgotPasswordUpdate({
          guid: this.guid,
          password: password
        });

        this.$router.push("/login");
      }
    },

    updateValidation() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }
    }
  }
};
</script>
